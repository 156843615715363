const NextI18Next = require('next-i18next').default;
const intervalPlural = require('i18next-intervalplural-postprocessor');
const path = require('path');

module.exports = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['de'],
  defaultNamespace: 'common',
  localePath: path.resolve('./public/static/locales'),
  use: [intervalPlural],
  fallbackNS: 'common',
  load: 'all',
});
